<template>
    <div class="feedback-container">
        <b-form-input v-show="false" :state="state"></b-form-input>
        <b-form-invalid-feedback> <span v-html="invalid"></span> <i class="fa fa-remove"></i></b-form-invalid-feedback>
        <b-form-valid-feedback v-if="valid"> {{ valid }} <i class="fa fa-check"></i></b-form-valid-feedback>
    </div>
</template>

<script>
import eventBus from '@/eventBus'

export default {
    props: {
        state: Boolean,
        valid: String,
        invalid: String,
    },
    watch: {
        state() {
            setTimeout(() => eventBus.$emit('triggerHighlight'), 250)
        },
    },
}
</script>

<style lang="css" scoped>
.feedback-container {
    margin-bottom: 7px;
}
</style>
