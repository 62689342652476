<template>
    <div class="app ">
        <div class="justify-content-center">
            <b-card no-body class="login-box">
                <b-card-body class="registered-card" v-if="story">
                    <h2 class="login-box__header">{{ story.content.headerText }}</h2>
                    <div class="registered-info-box">
                        <div class="info-text">
                            <div v-html="richtext" />
                        </div>
                    </div>
                    <b-btn variant="primary" @click="resend" size="lg">{{ story.content.buttonLabel }}</b-btn>
                    <Feedback
                        v-show="resendMessage.show"
                        :state="resendMessage.state"
                        :valid="resendMessage.message"
                        :invalid="resendMessage.message"
                    ></Feedback>
                    <p class="login-box__footer">
                        Already have an account? <router-link to="/auth/login">Log in</router-link>
                    </p>
                </b-card-body>
            </b-card>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'

export default {
    components: {
        Feedback,
        Loading,
    },
    data() {
        return {
            story: null,
            email: '',
            resendMessage: {
                state: true,
                message: '',
                show: false,
            },
        }
    },
    computed: {
        richtext() {
            if (this.story && this.story.content) {
                const content = this.$storyblok.richTextResolver
                    .render(this.story.content.content)
                    .replace(/{{email}}/g, `<span class="registered-email">${this.email}</span>`)
                return content
            } else return ''
        },
    },
    async created() {
        this.email = localStorage.getItem('registeredEmail')

        const spaceRes = await this.$storyblok.get(`cdn/spaces/me`)
        const cacheVersion = spaceRes.data.space.version

        const {
            data: {story},
        } = await this.$storyblok.get(`cdn/stories/views/registered`, {
            version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
            cv: cacheVersion,
        })

        this.story = story
    },
    methods: {
        resend() {
            this.resendMessage = {
                state: true,
                message: '',
                show: false,
            }

            this.$store.commit('loading/PROCESSING', `Resending`)
            this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/resendConfirmation/${encodeURIComponent(this.email)}`)
                .then(response => {
                    this.resendMessage = {
                        state: true,
                        message:
                            this.story && this.story.content
                                ? this.story.content.successMessage
                                : 'Email has been sent',
                        show: true,
                    }
                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    this.resendMessage = {
                        state: false,
                        message: exception.response.data.data,
                        show: true,
                    }

                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(exception, 'Could not resend confirmation')
                })
        },
    },
}
</script>

<style lang="scss">
.registered-card {
    .registered-info-box {
        h4 {
            font-family: 'Objectivity';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
            margin-bottom: 20px;
        }

        .registered-email {
            font-weight: 600;
        }
    }

    button.btn {
        margin-top: 40px;
        display: block;
        padding: 16px;
        width: 100%;
        text-align: center;
        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        background-color: #ffc32b;
        border: 0;
        text-shadow: none;

        &:hover:not(:disabled) {
            background-color: #000;
            color: #fff;
        }
    }
}
</style>
